import { FC, SyntheticEvent, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { debounce, get } from 'lodash';
import z from 'zod';

import { Entity } from 'domain/types/Entity';
import { Station } from 'domain/entities/Station';
import { ID } from 'domain/types/ID';
import { useCaseSearchStation } from 'application/stations/useCases/useCaseSearchStation';
import DEFAULT_DEBOUNCE_TIME from 'infrastructure/utils/defaultDebounceTime';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Autocomplete } from 'targets/web/components';
import { FormikTextField } from 'targets/web/modules/dashboard/components';

import { associationDetailsSchema } from './AssociationForm';

export type AssociationDetails = z.infer<typeof associationDetailsSchema>;

const blankStation = { name: 'All', code: '', state: '', id: 'all' as ID };

export const StationAssociationAutocomplete: FC<{
  disabled?: boolean;
  stationEditable?: boolean;
  optional?: boolean;
}> = ({ disabled, stationEditable, optional }) => {
  const { values, errors, touched, initialValues, setFieldValue } =
    useFormikContext<AssociationDetails>();
  const t = useTranslationPrefix('jobs.details_form.primary_details');

  const [stationPhrase, setStationPhrase] = useState('');
  const { items = [], isLoading } = useCaseSearchStation({ phrase: stationPhrase });

  const stations = useMemo(() => {
    if (!initialValues.station?.id) {
      return [blankStation, ...items];
    }

    if (!items.find(({ id }) => id === initialValues.station?.id)) {
      return [initialValues.station].concat(items);
    }

    return [blankStation, ...items];
  }, [initialValues.station, items]);

  const handleInputChange = useMemo(
    () =>
      debounce((event: SyntheticEvent, newInputValue: string) => {
        if (event?.type !== 'change' && newInputValue) {
          return;
        }

        setStationPhrase(newInputValue);
      }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  return (
    <Autocomplete<Entity<Pick<Station, 'name' | 'code'>>, false, true, boolean>
      label={t('station_label')}
      loading={isLoading}
      disabled={disabled || !stationEditable}
      options={stations}
      value={values.station === null ? blankStation : values.station}
      disableClearable
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      filterOptions={(x) => x}
      onInputChange={handleInputChange}
      data-testname="station"
      onChange={(_, value) =>
        setFieldValue('station', (value as { id: ID } | null)?.id === 'all' ? null : value)
      }
      renderInput={(params) => {
        const hasError = !!get(errors, 'station') && !!get(touched, 'station');

        return (
          <FormikTextField
            {...params}
            name="station"
            inputProps={{
              ...params.inputProps,
              required: !optional,
            }}
            error={hasError}
            helperText={hasError ? t('error') : undefined}
            label={t('station_label')}
            required={!optional}
          />
        );
      }}
    />
  );
};
