import { useQuery } from '@tanstack/react-query';

import { Airport } from 'domain/entities/Airport';
import { SearchAirportParams, StationRepository } from 'domain/repositories/IStationRepository';
import { Entity } from 'domain/types/Entity';
import { useHookInjection } from 'application/hooks';
import { useSortingAndPagination } from 'application/common';

export const useCaseSearchAirport = (params: SearchAirportParams) => {
  const { searchAirport } = useHookInjection<StationRepository>(StationRepository);

  const { paging, sorting, ...utils } = useSortingAndPagination<Entity<Airport>>('name');

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['AIRPORT', { ...params, ...paging, ...sorting }] as const,
    queryFn: ({ queryKey }) => searchAirport(queryKey[1]),
    initialData: { items: [], total: 0 },
    enabled: Boolean(params.stationId),
  });

  return {
    ...data,

    paging,
    sorting,
    ...utils,

    isLoading: isLoading || isFetching,
  };
};
