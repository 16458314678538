import { Decimal } from 'common/types/Decimal';

import { BillingStatus } from 'domain/entities/BillingStatus';
import { CancelJobRequest } from 'domain/entities/CancelJobRequest';
import { DamageFound } from 'domain/entities/DamageFound';
import { Job } from 'domain/entities/GetJobResponse';
import { InspectionArea } from 'domain/entities/InspectionArea';
import { JobRecurring } from 'domain/entities/JobRecurring';
import { JobStatus } from 'domain/entities/JobStatus';
import { JobStatusCount } from 'domain/entities/JobStatusCount';
import { JobSummary } from 'domain/entities/JobSummary';
import { JobSummaryCount } from 'domain/entities/JobsSummary';
import { UpdateJobRequest } from 'domain/entities/UpdateJobRequest';
import { CommonSearchParams } from 'domain/types/CommonSearchParams';
import { Entity } from 'domain/types/Entity';
import { ID } from 'domain/types/ID';
import { InjectableHook } from 'domain/types/InjectableHook';
import { PaginatedResult } from 'domain/types/PaginatedResult';

export type SearchJobsParams = Partial<
  CommonSearchParams & {
    phrase: string;
    status: JobStatus[];
    customerId: ID;
    stationId: ID;
    aircraftId: ID;
    serviceTypeId: ID;
  }
>;

export interface CreateJobData {
  jobRequestId?: ID; // While confirming a job request from the website
  name: string;
  notes?: string;
  aircraftId: ID;
  customerId: ID;
  poNumber?: string;
  woNumber?: string;
  squawkNumber?: string;
  due: Date;
  scheduledStart: Date;
  eta: Date;
  etd: Date;
  aircraftSerialNo: string;
  stationId: ID;
  airportId: ID;
  facilityId: ID;
  services: {
    serviceTypeId: ID;
    priceListId: ID;
    estimatedTime?: number;
    quantity?: number;
    order?: number;
  }[];
  recurringType: JobRecurring;
  recurringStartsAt?: Date;
  recurringEndsAt?: Date;
  flatRate?: Decimal;
}

export interface SubmitPreWorkQuestionnaireData {
  serviceId: ID;
  inspectionAreas: InspectionArea[];
  includeCommunication: boolean;
  toolsUsed: {
    bottles: number;
    microfiber: number;
    terrycloth: number;
    cleanKit: number;
    other: number;
  };
  damagesFound: DamageFound[];
  employeeCount: number;
  clientDamageCommunication?: { notes: string };
}

export type FetchJobParams = {
  id?: string;
};

export type CompleteJobParams = {
  jobId: ID;
};

export const JobRepository = Symbol('JobRepository');

type SetBillingStatusParams = {
  jobId: ID;
  billingStatus: BillingStatus;
  enabledPriceModifierIds?: ID[];
};

export type JobRepository = InjectableHook<{
  searchJobs(params: SearchJobsParams): Promise<PaginatedResult<Entity<JobSummary>>>;
  getJobsSummary(params: SearchJobsParams): Promise<JobSummaryCount>;
  fetchJob(params: FetchJobParams): Promise<Job>;
  getCountByStatus(params: SearchJobsParams): Promise<JobStatusCount>;

  createJob(params: CreateJobData): Promise<{ id: ID }>;
  updateJob(params: UpdateJobRequest): Promise<void>;
  setBillingStatus(params: SetBillingStatusParams): Promise<void>;
  cancelJob(params: CancelJobRequest): Promise<void>;
  completeJob(params: CompleteJobParams): Promise<void>;
}>;
