import { useFormikContext } from 'formik';
import { debounce, get } from 'lodash';
import { FC, SyntheticEvent, useMemo, useState } from 'react';

import { Entity } from 'domain/types/Entity';
import { Facility } from 'domain/entities/Facility';
import { useCaseSearchFacility } from 'application/stations/useCases/useCaseSearchFacility';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import DEFAULT_DEBOUNCE_TIME from 'infrastructure/utils/defaultDebounceTime';
import { Autocomplete } from 'targets/web/components';
import { FormikTextField } from 'targets/web/modules/dashboard/components';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';

import { PrimaryJobDetailsData } from './PrimaryJobDetails';

const isValueFreeSolo = (value: PrimaryJobDetailsData['facility']): boolean =>
  !!value.id && !!value.name && value.id === value.name;

export const FacilityAutocomplete: FC<{
  disabled?: boolean;
  optional?: boolean;
}> = ({ disabled, optional }) => {
  const { isOffline } = useNetworkStatus();

  const { values, errors, touched, initialValues, setFieldValue } =
    useFormikContext<PrimaryJobDetailsData>();
  const t = useTranslationPrefix('jobs.details_form.primary_details');

  const [facilityPhrase, setFacilityPhrase] = useState('');
  const { items = [], isLoading } = useCaseSearchFacility({ phrase: facilityPhrase });

  const facilities = useMemo(() => {
    if (!initialValues.facility?.id) {
      return items;
    }

    if (!items.find(({ id }) => id === initialValues.facility?.id)) {
      return [initialValues.facility].concat(items);
    }

    return items;
  }, [initialValues.facility, items]);

  const handleInputChange = useMemo(
    () =>
      debounce((event: SyntheticEvent, newInputValue: string) => {
        if (event?.type !== 'change' && newInputValue) {
          return;
        }

        setFacilityPhrase(newInputValue);
      }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  return (
    <Autocomplete<Entity<Omit<Facility, 'disabled'>>, false, false, boolean>
      label={t('facility_label')}
      loading={isLoading}
      disabled={disabled}
      freeSolo={isOffline}
      autoSelect={isOffline}
      options={facilities}
      value={values.facility?.id ? values.facility : null}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={(x) => x}
      onInputChange={handleInputChange}
      data-testname="facility"
      onChange={(_, value) =>
        setFieldValue('facility', typeof value === 'string' ? { name: value, id: value } : value)
      }
      renderInput={(params) => {
        const isFreeSolo = values.facility && isValueFreeSolo(values.facility);
        const hasError = !!get(errors, 'facility') && (!!get(touched, 'facility') || isFreeSolo);

        return (
          <FormikTextField
            {...params}
            name="facility"
            inputProps={{
              ...params.inputProps,
              required: !optional,
            }}
            error={hasError}
            helperText={
              hasError ? (isFreeSolo && isOffline ? t('error_free_solo') : t('error')) : undefined
            }
            label={t('facility_label')}
            required={!optional}
          />
        );
      }}
    />
  );
};
