import {
  FormControlLabel,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { ID } from 'api/domain/types';
import { Decimal, toFixed } from 'common/types/Decimal';
import { FC, useEffect, useMemo, useState } from 'react';

import { getPriceSummary } from 'domain/entities/JobPriceModifer';
import { JobSummary } from 'domain/entities/JobSummary';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Checkbox } from 'targets/web/components';
import Modal from 'targets/web/components/Modal';

type Props = Pick<JobSummary, 'priceModifiers' | 'services'> & {
  open: boolean;
  onClose: () => void;
  onAccept: (enabledPriceModifiers: ID[]) => void;
};

const StyledTableCell = styled(TableCell)(() => ({
  padding: '16px !important', // mui overrides this rule with table's default
}));

export const JobCostSummaryModal: FC<Props> = ({
  open,
  onClose,
  onAccept,
  priceModifiers,
  services,
}) => {
  const t = useTranslationPrefix('jobs.cost_summary');
  const [enabledPriceModifiers, setEnabledPriceModifiers] = useState<Record<ID, boolean>>(
    priceModifiers.reduce((acc, pm) => ({ ...acc, [pm.id]: !pm.disabled }), {}),
  );

  // update enabledPriceModifiers when priceModifiers change due to moving job to completed status
  useEffect(() => {
    setEnabledPriceModifiers(
      priceModifiers.reduce((acc, pm) => ({ ...acc, [pm.id]: !pm.disabled }), {}),
    );
  }, [priceModifiers]);

  const { total, subtotal, modifiers } = useMemo(
    () =>
      getPriceSummary(
        services,
        priceModifiers.map((pm) => ({
          ...pm,
          disabled: !enabledPriceModifiers[pm.id],
        })),
      ),
    [services, priceModifiers, enabledPriceModifiers],
  );
  return (
    <Modal
      onClose={onClose}
      open={open}
      title={t('title')}
      description={t('description')}
      maxWidth="xs"
      content={
        <Stack spacing={4} direction="column">
          <Table padding="none">
            <TableHead>
              <TableRow>
                <StyledTableCell>{t('name_label')}</StyledTableCell>
                <StyledTableCell sx={{ textAlign: 'right' }}>{t('cost_label')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {services.map((s) => (
                <TableRow key={s.id}>
                  <StyledTableCell>{s.name}</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'right' }}>${Decimal(s.rate)}</StyledTableCell>
                </TableRow>
              ))}
              <TableRow>
                <StyledTableCell sx={{ fontWeight: 'bold', marginBottom: 4, fontSize: 14 }}>
                  {t('subtotal_label')}
                </StyledTableCell>
                <StyledTableCell sx={{ textAlign: 'right', paddingBottom: 16, fontWeight: 'bold' }}>
                  ${toFixed(subtotal as Decimal, 2)}
                </StyledTableCell>
              </TableRow>
              {modifiers.map((m, ndx) => {
                return (
                  <TableRow key={m.type}>
                    <StyledTableCell sx={ndx === 0 ? { paddingTop: '32px !important' } : {}}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(enabledPriceModifiers[m.id])}
                            onChange={() => {
                              setEnabledPriceModifiers((pm) => ({ ...pm, [m.id]: !pm[m.id] }));
                            }}
                            onClick={(e) => e.stopPropagation()}
                          />
                        }
                        label={t(`modifier_label.${m.type}`, {
                          modifier: m.label,
                          absoluteModifier: m.absoluteLabel,
                        })}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        textAlign: 'right',
                        ...(ndx === 0 ? { paddingTop: '32px !important' } : {}),
                      }}
                    >
                      {Number(m.value) < 0 ? '-' : ''}${toFixed(m.value, 2).replace('-', '')}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
              <TableRow sx={{ backgroundColor: 'primary.action.hover' }}>
                <StyledTableCell sx={{ fontWeight: 'bold', fontSize: 14 }}>
                  {t('total_label')}
                </StyledTableCell>
                <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                  ${toFixed(total, 2)}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      }
      actions={{
        primary: {
          text: t('button_accept'),
          onClick: () => {
            onAccept(
              Object.entries(enabledPriceModifiers)
                .filter(([, enabled]) => enabled)
                .map(([pmId]) => pmId as ID),
            );
          },
        },
        secondary: { text: t('button_cancel'), onClick: () => onClose() },
      }}
    ></Modal>
  );
};
