import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { FC, useState } from 'react';

import { ID } from 'domain/types/ID';
import { useCaseCreateAssociation } from 'application/prices/useCases/useCaseCreateAssociation';
import { useCaseGetPriceList } from 'application/prices/useCases/useCaseGetPriceList';
import { useCaseSearchAssociations } from 'application/prices/useCases/useCaseSearchAssociations';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Modal } from 'targets/web/components';

import AssociationForm, { AssociationFormData } from './AssociationForm';

export const AddAssociationButton: FC<{ priceListId: ID }> = ({ priceListId }) => {
  const { data: priceList } = useCaseGetPriceList(priceListId);
  const { createAssociation } = useCaseCreateAssociation();
  const { refetch } = useCaseSearchAssociations();

  const [open, setOpen] = useState<boolean>(false);
  const t = useTranslationPrefix('configuration.price_list_details');
  const handleSubmit = async (data: AssociationFormData) => {
    await createAssociation({
      stationId: data.station?.id ?? undefined,
      customerId: data.customer?.id ?? undefined,
      priceListId,
    });
    refetch();
    setOpen(false);
  };
  return (
    <>
      <Button size="large" startIcon={<AddIcon />} onClick={() => setOpen(true)}>
        {t('add_association_button')}
      </Button>
      <Modal
        onClose={() => setOpen(false)}
        open={open}
        fullWidth
        content={
          <AssociationForm
            priceListName={priceList?.name ?? ''}
            initialValues={{}}
            onSubmit={handleSubmit}
            onCancel={() => setOpen(false)}
          />
        }
      />
    </>
  );
};
