import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import { entityId } from 'api/domain/types';
import { Form, Formik } from 'formik';
import { FC } from 'react';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Button, TextField } from 'targets/web/components';
import { StationAssociationAutocomplete } from 'targets/web/modules/configuration/components/StationAssociationAutocomplete';
import { PrimaryJobDetailsData } from 'targets/web/modules/jobs/components/JobDetailsForm/PrimaryJobDetails';

import { CustomerAssociationAutocomplete } from './CustomerAssociationAutocomplete';

export type AssociationFormData = {
  station?: PrimaryJobDetailsData['station'] | null;
  customer?: PrimaryJobDetailsData['customer'] | null;
};

export interface AssociationFormProps {
  disabled?: boolean;
  priceListName: string;
  initialValues: AssociationFormData;
  onSubmit: (data: AssociationFormData) => Promise<void>;
  onCancel: () => void;
  type?: 'add' | 'edit';
}

export const associationDetailsSchema = z.object({
  station: z
    .object({
      id: entityId(),
      code: z.string(),
      name: z.string(),
    })
    .nullable(),
  customer: z
    .object({
      id: entityId(),
      code: z.string().optional(),
      name: z.string().optional(),
    })
    .nullable(),
});

export const AssociationForm: FC<AssociationFormProps> = ({
  disabled,
  priceListName,
  initialValues,
  onSubmit,
  onCancel,
  type,
}) => {
  const t = useTranslationPrefix('configuration.price_list_details.association_form');

  const validationSchema = toFormikValidationSchema(associationDetailsSchema);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {({ isSubmitting }) => (
        <Form>
          <Stack gap={6} pt={6}>
            <Typography variant="h4">{t(type === 'edit' ? 'edit_title' : 'add_title')}</Typography>
            <Stack gap={4}>
              <TextField
                label={t('price_list_label')}
                readOnly
                disabled
                defaultValue={priceListName}
                size="small"
              />
              <CustomerAssociationAutocomplete disabled={disabled} customerEditable={!disabled} />
              <StationAssociationAutocomplete disabled={disabled} stationEditable={!disabled} />
            </Stack>
            <Stack direction="row" width={1} justifyContent="right" gap={2}>
              <Box>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="medium"
                  disabled={disabled || isSubmitting}
                  onClick={onCancel}
                  sx={{ mr: 2 }}
                >
                  {t('cancel_button')}
                </Button>
                <LoadingButton
                  loading={isSubmitting}
                  disabled={disabled}
                  size="medium"
                  variant="contained"
                  type="submit"
                >
                  {t(type === 'edit' ? 'edit_button' : 'create_button')}
                </LoadingButton>
              </Box>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default AssociationForm;
