import { IconButton, Stack } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { JobRecurring } from 'domain/entities/JobRecurring';
import { ID } from 'domain/types/ID';
import { useCaseGetJobDetails } from 'application/jobs/useCases/useCaseGetJobDetails';
import { useCaseGetServiceTypes } from 'application/services/useCases/useCaseGetServiceTypes';
import { useCaseGetJobRequest } from 'application/approvals/useCases/useCaseGetJobRequest';
import { useCaseUpdateJob } from 'application/jobs/useCases/useCaseUpdateJob';
import { useCaseReviewJob } from 'application/approvals/useCases/useCaseReviewJob';
import { useCaseCreateJob } from 'application/jobs/useCases/useCaseCreateJob';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Breadcrumbs, Loader } from 'targets/web/components';
import {
  FormikCancelSaveFooter,
  PageTitle,
  PersistFormikValues,
} from 'targets/web/modules/dashboard/components';
import { Button } from 'targets/web/components/Button';
import { ConfirmationModal } from 'targets/web/components/ConfirmationModal';
import { JobDetailsData, JobDetailsForm } from 'targets/web/modules/jobs/components';
import { approvalsRoutesConfig } from 'targets/web/modules/approvals/navigation/approvalsRoutesConfig';
import { JobRequestFormContainer } from 'targets/web/modules/approvals/components';
import useSnackbar from 'targets/web/modules/dashboard/hooks/useSnackbar';
import { usePersistedValues } from 'targets/web/modules/dashboard/hooks/usePersistedValues';
import { getTodaysDate } from 'targets/web/modules/jobs/utils';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';
import { OnboardingAddAndConfirmModal } from 'targets/web/modules/jobs/components/OnboardingAddAndConfirmModal';

const emptyInitialValues = {
  station: { id: ID(''), code: '', name: '', state: '' },
  airport: { id: ID(''), code: '', name: '' },
  facility: { id: ID(''), code: '', name: '' },
  customer: { id: ID(''), code: '', name: '' },
  aircraft: {
    id: ID(''),
    code: '',
    type: { name: '', code: '' },
    typeId: ID(''),
  },
  payment: {
    poNumber: '',
    woNumber: '',
    squawkNumber: '',
  },
  deliverBy: getTodaysDate(),
  services: [],
  recurringType: JobRecurring.Values.none,
  recurringStartsAt: undefined,
  recurringEndsAt: undefined,
  flatRate: undefined,
};

export const ConfirmJobView: FC = () => {
  const navigate = useNavigate();
  const { jobApprovalItemId = '' } = useParams();
  const { pathname } = useLocation();

  const notify = useSnackbar();
  const tBreadcrumbs = useTranslationPrefix('approvals.breadcrumbs');
  const t = useTranslationPrefix('approvals.confirm_job_request_view');
  const tGlobal = useTranslationPrefix('global');
  const { isOffline } = useNetworkStatus();

  const { isLoading: servicesLoading } = useCaseGetServiceTypes();

  const jobApprovalItemType = useMemo(() => {
    if (pathname.split('/').at(-1) === 'job') {
      return 'job';
    }

    return 'job-request';
  }, [pathname]);

  const { data: job, isLoading: jobDetailsLoading } = useCaseGetJobDetails({
    id: jobApprovalItemType === 'job' ? jobApprovalItemId : undefined,
  });
  const { data: jobRequest, isLoading: jobRequestDetailsLoading } = useCaseGetJobRequest({
    id: jobApprovalItemType === 'job-request' ? jobApprovalItemId : undefined,
  });

  const { createJob, isPending: isCreateJobPending } = useCaseCreateJob();
  const { updateJob, isPending: isUpdateJobPending } = useCaseUpdateJob();
  const { reviewJob } = useCaseReviewJob();

  const navigateToApprovalsView = useCallback(
    () => navigate(approvalsRoutesConfig.main),
    [navigate],
  );

  const persistFormKey = `confirm-job-${jobApprovalItemId}`;
  const { getPersistedValues, delPersistedValues } = usePersistedValues(persistFormKey);
  const [effectiveInitialValues, setEffectiveInitialValues] = useState<JobDetailsData>();
  const [onboardingModalVisible, setOnboardingModalVisible] = useState(false);

  useEffect(() => {
    getPersistedValues().then((values) => {
      if (values) {
        setEffectiveInitialValues((effectiveInitialValues) => ({
          ...effectiveInitialValues,
          ...values,
        }));
      }
    });
  }, [getPersistedValues]);

  useEffect(() => {
    if (effectiveInitialValues) return;

    if (jobApprovalItemType === 'job') {
      if (!job) return;

      setEffectiveInitialValues({
        station: job.station,
        airport: job.airport,
        facility: job.facility,
        customer: job.customer,
        aircraft: job.aircraft,
        payment: {
          poNumber: job.poNumber,
          woNumber: job.woNumber,
          squawkNumber: job.squawkNumber,
        },
        eta: job.eta,
        etd: job.etd,
        scheduled: job.scheduledStart,
        deliverBy: job.due,
        notes: job.notes ?? '',
        services: job.services
          .map(
            ({
              serviceType,
              serviceTypeId,
              priceList,
              estimatedTime,
              quantity,
              rate,
              discountPercentage,
              order,
              notes,
            }) => ({
              serviceType: {
                id: serviceTypeId,
                name: serviceType.name,
              },
              priceList: {
                id: priceList.id,
                name: priceList.name,
              },
              price: rate,
              name: serviceType.name,
              estimatedTime,
              quantity,
              discountPercentage,
              order,
              notes: notes ?? '',
            }),
          )
          .sort((a, b) => (a.order || 0) - (b.order || 0)),
        recurringType: job.recurringType,
        recurringStartsAt: job.recurringStartsAt,
        recurringEndsAt: job.recurringEndsAt,
        flatRate: job.flatRate ?? undefined,
      });
    }

    if (jobApprovalItemType === 'job-request' && jobRequest) {
      setEffectiveInitialValues({
        ...emptyInitialValues,
        station: jobRequest?.station || emptyInitialValues.station,
        airport: jobRequest?.airport || emptyInitialValues.airport,
        facility: emptyInitialValues.facility,
      });
    }
  }, [effectiveInitialValues, job, jobApprovalItemType, jobRequest]);

  const handleSubmit = useCallback(
    async (values: JobDetailsData) => {
      if (jobApprovalItemType === 'job') {
        await updateJob({
          jobId: ID(jobApprovalItemId),
          data: {
            aircraftId: values.aircraft.id,
            customerId: values.customer.id,
            scheduledStart: values.scheduled ? new Date(values.scheduled) : new Date(),
            due: values.deliverBy ? new Date(values.deliverBy) : new Date(),
            etd: values.etd ? new Date(values.etd) : new Date(),
            eta: values.eta ? new Date(values.eta) : new Date(),
            notes: values.notes,
            services: values.services.map((s, order) => ({
              serviceTypeId: s.serviceType.id,
              priceListId: s.priceList.id,
              quantity: s.quantity,
              estimatedTime: s.estimatedTime,
              price: s.price,
              discountPercentage: s.discountPercentage,
              order,
              notes: s.notes ?? '',
            })),
            poNumber: values.payment.poNumber,
            woNumber: values.payment.woNumber,
            squawkNumber: values.payment.squawkNumber,
            flatRate: values.flatRate,
          },
        });

        await reviewJob({ jobId: ID(jobApprovalItemId), accepted: true });
      } else {
        await createJob({
          jobRequestId: ID(jobApprovalItemId),
          notes: values.notes,
          stationId: values.station.id,
          airportId: values.airport.id,
          facilityId: values.facility.id,
          customerId: values.customer.id,
          name: values.aircraft.code ?? values.aircraft.serialNumber ?? '',
          aircraftId: values.aircraft.id,
          due: values.deliverBy ? new Date(values.deliverBy) : new Date(),
          scheduledStart: values.scheduled ? new Date(values.scheduled) : new Date(),
          eta: values.eta ? new Date(values.eta) : new Date(),
          etd: values.etd ? new Date(values.etd) : new Date(),
          aircraftSerialNo: '',
          services: values.services.map((s) => ({
            serviceTypeId: s.serviceType.id,
            priceListId: s.priceList.id,
            quantity: s.quantity,
            estimatedTime: s.estimatedTime,
            price: s.price,
            discountPercentage: s.discountPercentage,
          })),
          poNumber: values.payment.poNumber,
          woNumber: values.payment.woNumber,
          squawkNumber: values.payment.squawkNumber,
          recurringType: values.recurringType,
          recurringStartsAt: values.recurringStartsAt,
          recurringEndsAt: values.recurringEndsAt,
          flatRate: values.flatRate,
        });
      }

      notify(t('notification.success'), {
        variant: 'success',
      });
      navigateToApprovalsView();
      delPersistedValues();
    },
    [
      createJob,
      delPersistedValues,
      jobApprovalItemId,
      jobApprovalItemType,
      navigateToApprovalsView,
      notify,
      reviewJob,
      t,
      updateJob,
    ],
  );

  useEffect(() => {
    if (job && job.status !== 'submitted') {
      navigate(`/jobs/${job.id}/summary`);
    }
  }, [job, navigate]);

  if (!effectiveInitialValues || jobDetailsLoading || jobRequestDetailsLoading || servicesLoading) {
    return (
      <Stack height={1} justifyContent="center">
        <Loader />
      </Stack>
    );
  }

  return (
    <>
      <Stack direction="row" width={1} justifyContent="flex-start" alignItems="center" gap={4}>
        <ConfirmationModal
          title={t('back_modal.title')}
          subtitle={t('back_modal.subtitle')}
          confirmText={tGlobal('confirm')}
          cancelText={tGlobal('cancel')}
          onConfirm={navigateToApprovalsView}
          triggerButton={(onClick: () => void) => (
            <IconButton onClick={isOffline ? navigateToApprovalsView : onClick} color="primary">
              <ArrowBackIcon />
            </IconButton>
          )}
        />

        <Stack direction="column" alignItems="flex-start" justifyContent="space-between" flex={1}>
          <Breadcrumbs
            items={[
              {
                label: tBreadcrumbs('approve_and_decline'),
                url: approvalsRoutesConfig.main,
              },
            ]}
          />

          <PageTitle title={t('title')} />
        </Stack>
        <Stack>
          <Button size="large" variant="outlined" onClick={() => setOnboardingModalVisible(true)}>
            <QuestionMarkIcon />
          </Button>
        </Stack>
      </Stack>

      {jobApprovalItemType === 'job-request' && jobRequest && (
        <JobRequestFormContainer jobRequest={jobRequest} />
      )}

      <JobDetailsForm
        job={job}
        stationEditable={jobApprovalItemType === 'job-request'}
        customerEditable
        aircraftEditable
        facilityEditable
        initialValues={effectiveInitialValues}
        onSubmit={handleSubmit}
        footer={
          <>
            {jobApprovalItemId && isOffline && (
              <PersistFormikValues persistInvalid name={persistFormKey} />
            )}
            <FormikCancelSaveFooter
              onCancel={navigateToApprovalsView}
              isLoading={isCreateJobPending || isUpdateJobPending}
            />
          </>
        }
      />

      <OnboardingAddAndConfirmModal
        open={onboardingModalVisible}
        onClose={() => setOnboardingModalVisible(false)}
      />
    </>
  );
};
