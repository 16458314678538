import { useQuery } from '@tanstack/react-query';

import { SearchFacilityParams, StationRepository } from 'domain/repositories/IStationRepository';
import { Entity } from 'domain/types/Entity';
import { Facility } from 'domain/entities/Facility';
import { useSortingAndPagination } from 'application/common';
import { useHookInjection } from 'application/hooks';

export const useCaseSearchFacility = (params: SearchFacilityParams) => {
  const { searchFacility } = useHookInjection<StationRepository>(StationRepository);

  const { paging, sorting, ...utils } = useSortingAndPagination<Entity<Facility>>('name');

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['FACILITY', { ...params, ...paging, ...sorting }] as const,
    queryFn: ({ queryKey }) => searchFacility(queryKey[1]),
    initialData: { items: [], total: 0 },
  });

  return {
    ...data,

    paging,
    sorting,
    ...utils,

    isLoading: isLoading || isFetching,
  };
};
