import { useFormikContext } from 'formik';
import { debounce, get } from 'lodash';
import { FC, SyntheticEvent, useMemo, useState } from 'react';

import { Airport } from 'domain/entities/Airport';
import { Entity } from 'domain/types/Entity';
import { useCaseSearchAirport } from 'application/stations/useCases/useCaseSearchAirport';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import DEFAULT_DEBOUNCE_TIME from 'infrastructure/utils/defaultDebounceTime';
import { Autocomplete } from 'targets/web/components';
import { FormikTextField } from 'targets/web/modules/dashboard/components';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';

import { PrimaryJobDetailsData } from './PrimaryJobDetails';

const isValueFreeSolo = (value: PrimaryJobDetailsData['airport']): boolean =>
  !!value.id && !!value.name && value.id === value.name;

export const AirportAutocomplete: FC<{
  disabled?: boolean;
  stationEditable?: boolean;
  optional?: boolean;
}> = ({ disabled, stationEditable, optional }) => {
  const { isOffline } = useNetworkStatus();

  const { values, errors, touched, initialValues, setFieldValue } =
    useFormikContext<PrimaryJobDetailsData>();
  const t = useTranslationPrefix('jobs.details_form.primary_details');

  const selectedStationId = values.station?.id;
  const [airportPhrase, setAirportPhrase] = useState('');
  const { items = [], isLoading } = useCaseSearchAirport({
    phrase: airportPhrase,
    stationId: selectedStationId,
  });

  const airports = useMemo(() => {
    if (!initialValues.airport?.id) {
      return items;
    }

    if (!items.find(({ id }) => id === initialValues.airport?.id)) {
      return [initialValues.airport].concat(items);
    }

    return items;
  }, [initialValues.airport, items]);

  const handleInputChange = useMemo(
    () =>
      debounce((event: SyntheticEvent, newInputValue: string) => {
        if (event?.type !== 'change' && newInputValue) {
          return;
        }

        setAirportPhrase(newInputValue);
      }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  return (
    <Autocomplete<Entity<Omit<Airport, 'disabled'>>, false, false, boolean>
      label={t('airport_label')}
      loading={isLoading}
      disabled={disabled || !stationEditable || !selectedStationId}
      freeSolo={isOffline}
      autoSelect={isOffline}
      options={airports}
      value={values.airport?.id ? values.airport : null}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={(x) => x}
      onInputChange={handleInputChange}
      data-testname="airport"
      onChange={(_, value) =>
        setFieldValue('airport', typeof value === 'string' ? { name: value, id: value } : value)
      }
      renderInput={(params) => {
        const isFreeSolo = values.airport && isValueFreeSolo(values.airport);
        const hasError = !!get(errors, 'airport') && (!!get(touched, 'airport') || isFreeSolo);

        return (
          <FormikTextField
            {...params}
            name="airport"
            inputProps={{
              ...params.inputProps,
              required: !optional,
            }}
            error={hasError}
            helperText={
              hasError ? (isFreeSolo && isOffline ? t('error_free_solo') : t('error')) : undefined
            }
            label={t('airport_label')}
            required={!optional}
          />
        );
      }}
    />
  );
};
