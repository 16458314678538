import Pagination from 'common/types/Pagination';
import SortingOptions from 'common/types/SortingOptions';

import { InjectableHook } from 'domain/types/InjectableHook';
import { PaginatedResult } from 'domain/types/PaginatedResult';
import { Entity } from 'domain/types/Entity';
import { Station } from 'domain/entities/Station';
import { ID } from 'domain/types/ID';
import { Facility } from 'domain/entities/Facility';
import { Airport } from 'domain/entities/Airport';

export type SearchStationParams = Partial<
  Pagination &
    SortingOptions & {
      phrase: string;
    }
>;

export type SearchFacilityParams = Partial<
  Pagination &
    SortingOptions & {
      phrase: string;
    }
>;

export type SearchAirportParams = Partial<
  Pagination &
    SortingOptions & {
      phrase?: string;
      stationId?: ID;
    }
>;

export const StationRepository = Symbol('StationRepository');

export type StationRepository = InjectableHook<{
  searchStation(params: SearchStationParams): Promise<PaginatedResult<Entity<Station>>>;
  searchFacility(params: SearchFacilityParams): Promise<PaginatedResult<Entity<Facility>>>;
  searchAirport(params: SearchAirportParams): Promise<PaginatedResult<Entity<Airport>>>;
  getStation(params: { id: ID }): Promise<Entity<Station>>;
}>;
