import { FC, SyntheticEvent, useMemo, useState } from 'react';
import { debounce } from 'lodash';

import { Entity } from 'domain/types/Entity';
import { Station } from 'domain/entities/Station';
import { ID } from 'domain/types/ID';
import { useCaseSearchStation } from 'application/stations/useCases/useCaseSearchStation';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import DEFAULT_DEBOUNCE_TIME from 'infrastructure/utils/defaultDebounceTime';
import { Autocomplete } from 'targets/web/components';

export const StationAutocomplete: FC<{
  initialValueIds?: ID[];
  onChange: (stations: Entity<Station>[]) => void;
}> = ({ initialValueIds, onChange }) => {
  const t = useTranslationPrefix('components.filters_container');

  const [stationPhrase, setStationPhrase] = useState('');
  const { items, isLoading } = useCaseSearchStation({ phrase: stationPhrase });

  const handleInputChange = useMemo(
    () =>
      debounce((event: SyntheticEvent, newInputValue: string) => {
        if (event?.type !== 'change' && newInputValue) {
          return;
        }

        setStationPhrase(newInputValue);
      }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  const initialValue = useMemo(() => {
    if (!initialValueIds) return [];
    return initialValueIds
      .map((id) => items.find((item): item is Entity<Station> => item.id === id))
      .filter((item): item is Entity<Station> => Boolean(item));
  }, [initialValueIds, items]);

  return (
    <Autocomplete<Entity<Station>, true, false, false>
      label={t('station_label')}
      loading={isLoading}
      options={items}
      getOptionLabel={(option) => option.name}
      defaultValue={initialValue}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={(x) => x}
      onInputChange={handleInputChange}
      data-testname="stationFilter"
      onChange={(_, value) => {
        onChange(value);
      }}
      placeholder={t('station_placeholder')}
      multiple
    />
  );
};
